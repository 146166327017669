import displayIcon from "@/components/displayIcon/displayIcon.vue";

export default {
  name: "homeBtn",
  components: {
    displayIcon
  },
  props: {
    name: {
      type: String,
      default: null
    },
    color: {
      type: String,
      default: null,
      validator: function(value) {
        // La valeur passée doit être l'une de ces chaines de caractères
        return (
          ["green", "secondary", "red", "purple", "lightBlue"].indexOf(
            value
          ) !== -1
        );
      }
    },
    icon: {
      type: String,
      default: null
    }
  },
  computed: {
    borderColor() {
      switch (this.color) {
        case "green":
          return "#19B55D";
        case "secondary":
          return "#558B90";
        case "red":
          return "#B5191D";
        case "purple":
          return "#8463C5";
        case "lightBlue":
          return "#48B1BD";
      }
      return this.color;
    }
  }
};
